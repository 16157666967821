import React from 'react';
import './Layout.css';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

interface LayoutProps {
  isLoginPage: boolean;
}

const Layout: React.FC<LayoutProps> = ({ isLoginPage }) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const handleRedirectToProfile = () => {
    navigate('/account-details')
  };

  const handleRedirectToEventSearchPage = () => {
    navigate('/return-results');
  };

  return (
    <div className="header-container">
      <div className="layout">
        <img
          src="/sport_sphere_logo.png"
          alt="Logo"
          onClick={handleRedirectToEventSearchPage} />
      </div>
      {!isLoginPage ? (
        <div className="logout-container">
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              Settings
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><button className="dropdown-item" onClick={handleRedirectToProfile}>Profile</button></li>
              <li><button className="dropdown-item" onClick={() =>
                logout({
                  logoutParams: { returnTo: window.location.origin },
                })
              }>Logout</button></li>
            </ul>
          </div>
        </div>) : null}
    </div>
  );
};

export default Layout;
