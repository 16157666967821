import React, { useState } from 'react';
import './EventSearchCarousel.css';

interface EventResult {
    Id: number,
    Name: string;
    Street: string;
    City: string;
    County: string;
    PostCode: string;
    EventDate: Date;
    EventType: string;
    Distance: string;
    EventOrganiser: string;
    PresignedImageURL: string;
    isFavourited?: boolean;
}

interface CarouselProps {
    RecommendedEventResults: EventResult[];
    HandleRedirect: (eventId: number) => void;
}

const EventSearchCarousel: React.FC<CarouselProps> = ({ RecommendedEventResults, HandleRedirect }) => {
    const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);

    const handlePrev = () => {
        setCurrentCarouselIndex((prevIndex) =>
            (prevIndex === 0 ? RecommendedEventResults.length - 1 : prevIndex - 1)
        );
    };

    const handleNext = () => {
        setCurrentCarouselIndex((prevIndex) =>
            (prevIndex === RecommendedEventResults.length - 1 ? 0 : prevIndex + 1)
        );
    };

    if (RecommendedEventResults.length !== 0) {
        return (
            <div className="container carousel-container mt-4">
                <div className="row align-items-center">
                    {/* Carousel Section */}
                    <div className="col-md-8">
                        <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
                            {/* Indicators */}
                            <div className="carousel-indicators">
                                {RecommendedEventResults.map((_, index) => (
                                    <div
                                        key={index}
                                        data-slide-to={index}
                                        className={index === currentCarouselIndex ? "active" : ""}
                                        onClick={() => setCurrentCarouselIndex(index)}
                                        style={{ cursor: "pointer" }}
                                    ></div>
                                ))}
                            </div>


                            {/* Slides */}
                            <div className="carousel-inner">
                                {RecommendedEventResults.map((event, index) => (
                                    <div
                                        key={event.Id}
                                        className={`carousel-item ${index === currentCarouselIndex ? "active" : ""}`}
                                    >
                                        <img
                                            src={event.PresignedImageURL}
                                            alt={event.Name}
                                            className="d-block w-100 img-fluid"
                                            onClick={() => HandleRedirect(event.Id)}
                                            style={{ cursor: "pointer", borderRadius: "10px" }}
                                        />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>{event.Name}</h5>
                                        </div>
                                    </div>
                                ))}
                            </div>


                            {/* Controls */}
                            <button
                                className="carousel-control-prev"
                                onClick={handlePrev}
                            >
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </button>
                            <button
                                className="carousel-control-next"
                                onClick={handleNext}
                            >
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </button>
                        </div>
                    </div>

                    {/* Sidebar Section */}
                    <div className="col-md-4">
                        <div className="result shadow-sm">
                            {RecommendedEventResults.map((event, index) => (
                                <p
                                    key={event.Id}
                                    className={`event-item ${index === currentCarouselIndex ? "active" : ""}`}
                                    onClick={() => setCurrentCarouselIndex(index)}
                                    style={{
                                        cursor: "pointer",
                                        fontWeight: index === currentCarouselIndex ? "bold" : "normal",
                                    }}
                                >
                                    {`${index + 1}. ${event.Name.length > 21 ? event.Name.slice(0, 22) + "..." : event.Name}`}
                                </p>
                            ))}
                        </div>

                    </div>
                </div>
            </div>)
    }

    return (
        null
    )
}

export default EventSearchCarousel;