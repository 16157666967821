import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Correct import
import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosResponse } from 'axios';
import './eventSearchPage.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Includes Popper.js
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import EventTypeTag from './components/eventTypeTag';
import SearchComponent from './components/SearchComponent';
import EventSearchCarousel from './components/EventSearchCarousel';
import Layout from './components/Layout';

interface EventResult {
  Id: number,
  Name: string;
  Street: string;
  City: string;
  County: string;
  PostCode: string;
  EventDate: Date;
  EventType: string;
  Distance: string;
  EventOrganiser: string;
  PresignedImageURL: string;
  isFavourited?: boolean;
}

type Favourite = {
  UserId: number;
  EventId: number;
  EventName: string;
};

interface ApiResponse {
  success: boolean;
  message?: string;
}

interface EventSuggestion {
  EventId: number;
  EventName: string;
}

const EventSearchPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [eventResults, setEventResults] = useState<EventResult[]>([]);
  const [recommendedEventResults, setRecommendedEventResults] = useState<EventResult[]>([]);
  const [searchMethod, setSearchMethod] = useState('name');
  const [sortOption, setSortOption] = useState<string>('date-desc');
  const [viewMode, setViewMode] = useState('list');
  const [favourites, setFavourites] = useState<Favourite[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [recommendedEventsSearchPerformed, setRecommendedEventsSearchPerformed] = useState(false);
  const [itemsPerPage] = useState(10);
  const [eventSuggestions, setEventSuggestions] = useState<EventSuggestion[]>([]);
  const activeIndex = -1
  const navigate = useNavigate();
  const { getAccessTokenSilently, handleRedirectCallback, isAuthenticated } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        // Handle the redirect callback from Auth0
        await handleRedirectCallback();
        console.log('User logged in successfully');
      } catch (error) {
        console.error('Error handling Auth0 callback:', error);
      }
    };

    handleAuthCallback();
  }, [handleRedirectCallback, location.search]);

  // Calculate the index of the first and last item of the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleEventRecommendations = useCallback(async () => {
    if (!isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }

    try {
      const token = await getAccessTokenSilently();

      const response: AxiosResponse<EventResult[]> = await axios.get<EventResult[]>(
        `https://sportsphere-backend.fly.dev/getrecommendedevents`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      );

      if (response.data && Array.isArray(response.data)) {
        const fetchedResults: EventResult[] = response.data.map((item: any) => ({
          ...item,
          EventDate: new Date(item.EventDate),
        }));

        console.log(fetchedResults);
        setRecommendedEventResults(fetchedResults);
      }
    } catch (error) {
      console.error("Error fetching recommendations", error);
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    // Fetch recommended events only when the component mounts
    if (!recommendedEventsSearchPerformed && isAuthenticated) {
      handleEventRecommendations();
      setRecommendedEventsSearchPerformed(true);
    }
  }, [isAuthenticated, recommendedEventsSearchPerformed, handleEventRecommendations]);


  const sortEventResults = useCallback((results: EventResult[]) => {
    switch (sortOption) {
      case 'alphabetical-asc':
        return results.sort((a, b) => a.Name.localeCompare(b.Name));
      case 'alphabetical-desc':
        return results.sort((a, b) => b.Name.localeCompare(a.Name));
      case 'date-desc':
        return results.sort((a, b) => new Date(a.EventDate).getTime() - new Date(b.EventDate).getTime());
      default:
        return results;
    }
  }, [sortOption]);

  const handleSearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      let response: AxiosResponse<EventResult[]>;

      if (searchMethod === 'name') {
        response = await axios.get<EventResult[]>(`https://sportsphere-backend.fly.dev:443/getresultsbyeventname?eventName=${searchTerm}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else if (searchMethod === 'location') {
        response = await axios.get<EventResult[]>(`https://sportsphere-backend.fly.dev:443/getresultsbyeventlocation?location=${searchTerm}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        response = await axios.get<EventResult[]>(`https://sportsphere-backend.fly.dev:443/getresultsbyeventorg?eventOrg=${searchTerm}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      await updateFavourites();

      if (response.data && Array.isArray(response.data)) {
        const fetchedResults: EventResult[] = response.data
          .map((item: any) => ({
            ...item,
            EventDate: new Date(item.EventDate),
            isFavourited: isFavourite(item.Id) // Initialize isFavourited
          }));

        const sortedResults = sortEventResults(fetchedResults);
        setEventResults(sortedResults);
      } else {
        setEventResults([]);
      }

      setSearchPerformed(true);
      setCurrentPage(1);
    } catch (error) {
      console.error('Error fetching event data:', error);
    }
  };

  const currentItems = eventResults.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(eventResults.length / itemsPerPage);

  useEffect(() => {
    if (eventResults.length > 0) {
      const sortedResults = sortEventResults([...eventResults]);
      setEventResults(sortedResults);
    }
  }, [sortOption, eventResults, sortEventResults]);

  const handleListView = () => {
    setViewMode('list');
  };

  const handleGridView = () => {
    setViewMode('grid');
  };

  const handleRedirect = (eventId: number) => {
    navigate(`/event/${eventId}`);
  };

  const fetchFavourites = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get<Favourite[]>(`https://sportsphere-backend.fly.dev:443/getfavourites`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFavourites(response.data);
      return response.data
    } catch (error) {
      console.error("Error fetching favourites:", error);
      return [];
    }
  }, [getAccessTokenSilently]);

  const updateFavourites = async () => {
    try {
      const favData = await fetchFavourites();
      setFavourites(favData);
    } catch (error) {
      console.error('Error fetching favorites:', error);
    }
  };

  useEffect(() => {
    // Fetch favorites when the component mounts
    if (!isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }
    const fetchInitialFavorites = async () => {
      try {
        const favData = await fetchFavourites();
        setFavourites(favData);
      } catch (error) {
        console.error('Error fetching initial favorites:', error);
      }
    };

    fetchInitialFavorites();
  }, [isAuthenticated, getAccessTokenSilently, fetchFavourites]);

  useEffect(() => {
    // Function to update favorites based on the current search results
    if (!isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }
    const updateFavorites = async () => {
      const favData = await fetchFavourites();
      setFavourites(favData);
    };

    // Call updateFavorites whenever search results change
    if (eventResults.length > 0) {
      updateFavorites();
    }
  }, [eventResults, isAuthenticated, fetchFavourites]);

  const isFavourite = (eventId: number): boolean => {
    if (!favourites) {
      return false;
    }
    console.log("isFavourite favourites: ", favourites)
    console.log("isFavourite eventId: ", eventId)
    return favourites.some(favourite => favourite.EventId === eventId);
  };

  const handleStarClick = async (id: number, eventName: string) => {
    if (!isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }

    const isFavourited = eventResults.find(event => event.Id === id)?.isFavourited;
    const payload = { EventId: id, EventName: eventName };

    try {
      const token = await getAccessTokenSilently();
      if (isFavourited) {
        // Delete favourite
        const response = await axios.delete<ApiResponse>(`https://sportsphere-backend.fly.dev:443/deletefavourite`, {
          data: payload,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          // Fetch the updated favourites list
          const updatedFavourites = await axios.get<Favourite[]>(`https://sportsphere-backend.fly.dev:443/getfavourites`, {
            withCredentials: true,
          });
          setFavourites(updatedFavourites.data);
        }
      } else {
        // Add favourite
        const response = await axios.post<ApiResponse>(`https://sportsphere-backend.fly.dev:443/addfavourite`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          // Fetch the updated favourites list
          const updatedFavourites = await axios.get<Favourite[]>(`https://sportsphere-backend.fly.dev:443/getfavourites`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setFavourites(updatedFavourites.data);
        }
      }
      // Update the eventResults to reflect the new isFavourited state
      setEventResults(prevResults =>
        prevResults.map(event =>
          event.Id === id ? { ...event, isFavourited: !isFavourited } : event
        )
      );
    } catch (error) {
      console.error('Error handling star click:', error);
    }
  };

  return (
    <div className="container mt-5">
      <Layout isLoginPage={false} />

      <SearchComponent
        handleSearch={handleSearch}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchMethod={searchMethod}
        setSearchMethod={setSearchMethod}
        eventSuggestions={eventSuggestions}
        activeIndex={activeIndex}
        setEventSuggestions={setEventSuggestions}
      />

      {searchPerformed ? (
        <div className="row">
          <div className="col-12">
            <div className="card card-margin">
              <div className="card-body">
                <div className="row search-body">
                  <div className="col-lg-12">
                    <div className="search-result">
                      <div className="result-header">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="records">Showing: <b>{indexOfFirstItem + 1}-{indexOfLastItem}</b> of <b>{eventResults.length}</b> result</div>
                          </div>
                          <div className="col-lg-6">
                            <div className="result-actions">
                              <div className="result-sorting">
                                <span>Sort By:</span>
                                <select className="form-control border-0" value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
                                  <option value="date-desc">Date</option>
                                  <option value="alphabetical-asc">Names (A-Z)</option>
                                  <option value="alphabetical-desc">Names (Z-A)</option>
                                </select>
                              </div>
                              <div className="result-views">
                                <button type="button" className="btn btn-soft-base btn-icon" onClick={handleListView}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-list"
                                  >
                                    <line x1="8" y1="6" x2="21" y2="6"></line>
                                    <line x1="8" y1="12" x2="21" y2="12"></line>
                                    <line x1="8" y1="18" x2="21" y2="18"></line>
                                    <line x1="3" y1="6" x2="3" y2="6"></line>
                                    <line x1="3" y1="12" x2="3" y2="12"></line>
                                    <line x1="3" y1="18" x2="3" y2="18"></line>
                                  </svg>
                                </button>
                                <button type="button" className="btn btn-soft-base btn-icon" onClick={handleGridView}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-grid"
                                  >
                                    <rect x="3" y="3" width="7" height="7"></rect>
                                    <rect x="14" y="3" width="7" height="7"></rect>
                                    <rect x="14" y="14" width="7" height="7"></rect>
                                    <rect x="3" y="14" width="7" height="7"></rect>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="result-body">
                        {viewMode === 'list' ? (
                          // List (Table) View
                          <div className="table-responsive">
                            <table className="table widget-26">
                              <tbody>
                                {currentItems.length > 0 &&
                                  currentItems.map((result, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="widget-26-job-title">
                                          <button onClick={() => handleRedirect(result.Id)}>{result.Name}</button>
                                          <p className="m-0">{result.EventDate.toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                          })}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="widget-26-job-info">
                                          <p className="type m-0">{result.City}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="widget-26-job-salary">{result.EventOrganiser}</div>
                                      </td>
                                      <td>
                                        <EventTypeTag eventType={result.EventType} />
                                      </td>
                                      <td>
                                        <div className="widget-26-job-starred">
                                          <button
                                            className={result.isFavourited ? 'star active' : 'star'}
                                            onClick={() => handleStarClick(result.Id, result.Name)}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth="2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              className="feather feather-star"
                                            >
                                              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                            </svg>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          // Grid (Tile) View
                          <div className="result-body">
                            <div className="tile-container">
                              {currentItems.length > 0 && currentItems.map((result, index) => (
                                <div key={index} className="tile-item">
                                  <button onClick={() => handleRedirect(result.Id)}>
                                    {result.Name}
                                  </button>
                                  <p>{result.EventDate.toLocaleDateString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                  })}</p>
                                  <p>{result.City}</p>
                                  <p>{result.EventOrganiser}</p>
                                  <p>{result.EventType}</p>
                                  <button
                                    className={result.isFavourited ? 'star active' : 'star'}
                                    onClick={() => handleStarClick(result.Id, result.Name)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-star"
                                    >
                                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                    </svg>
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <nav className="d-flex justify-content-center">
                  <ul className="pagination pagination-base pagination-boxed pagination-square mb-0">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <button className="page-link no-border" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                        «
                      </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                        <button className="page-link no-border" onClick={() => setCurrentPage(index + 1)}>
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <button className="page-link no-border" onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                        »
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EventSearchCarousel RecommendedEventResults={recommendedEventResults} HandleRedirect={handleRedirect} />
      )}
    </div>
  )
};

export default EventSearchPage;
