import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';

interface DeleteAccountButtonProps {
    UserDetails: UserDetails;
}

type UserDetails = {
    Username: string;
    Email: string;
    Postcode: string;
};

const DeleteAccountButton: React.FC<DeleteAccountButtonProps> = (props: DeleteAccountButtonProps) => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const apiDeleteAccount = `https://sportsphere-backend.fly.dev:443/removeuser`

    const handleDeleteClick = () => {
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        const handleDeleteAccount = async () => {
            try {
                const payload = { Username: props.UserDetails.Username };
                await axios.delete(apiDeleteAccount, {
                    data: payload,
                    withCredentials: true,
                });

                localStorage.removeItem('token');

                navigate('/login');
            } catch (error) {
                console.error('Error deleting account:', error);
            }
        }
        console.log('Account deleted');
        handleDeleteAccount();
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    return (
        <>
            <Button variant="danger" onClick={handleDeleteClick}>
                Delete Account
            </Button>

            <Modal show={showModal} onHide={handleCancelDelete} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Account Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete your account? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancelDelete}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Yes, Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteAccountButton;
