import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    // Trigger login when this page is loaded
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/return-results`, // Use authorizationParams for redirect_uri
        // audience: "https://sportsphere-backend.fly.dev",
      },
    });
  }, [loginWithRedirect]);

  return (
    <div>
      <h1>Redirecting to login...</h1>
    </div>
  );
};

export default LoginPage;
